import { useNavigate } from 'react-router-dom'
import { Button, Card, Col, Container, Ratio, Row, Stack } from 'react-bootstrap'
import InputText from '../../../components/common/InputText'
import { IoIosSearch, IoMdArrowDroprightCircle } from 'react-icons/io'
import { SvgIcons } from '../../../components/common/SvgIcons'
import { LastWordData } from '../../../dummyData/LastWordData'
import { useEffect, useState } from 'react'
import { getproclamationList } from '../../../services/homeService'
import { toast } from 'react-toastify'
import Loader from '../../../components/common/Loder'
import moment from 'moment'
import { truncateText, truncateTitle } from '../../../helpers/helpersMethods'
import InfiniteScroll from 'react-infinite-scroll-component'

export const AllLastWords = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [loadingsearch, setLoadingSearch] = useState(false);
    const [data, setData] = useState<any[]>([]);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [searchTerm, setSearchTerm] = useState<any>('');
    const [totalPage, setTotalPage] = useState(1);


    // useEffect(()=>{
    //     console.log(pageNumber)
    // },[pageNumber])


    useEffect(() => {
        fetchData();
    }, [searchTerm, pageNumber]);

    const fetchData = async () => {
        setLoading(true);
        try {
            //setData([]); // Reset data before fetching new data
            if (pageNumber === 1) {
                setData([]); // Reset data only when the page number is 1 (new search)
                //setLoadingSearch(true);
            }

            await getproclamationList({ pagenumber: pageNumber, searchtext: searchTerm.trim() }).then(
                (resp) => {
                    setLoading(false);
                    setLoadingSearch(false);
                    const newData = resp?.data.proclamation;
                    setTotalPage(resp?.data.totalpage);
                    setData((prevData: any[]) => [...prevData, ...newData]);
                   
                },
                (error: any) => {
                    setLoading(false);
                    const err = error?.response?.data;
                    if (err?.statusCode === 400) {
                        toast.error(err.msg);
                    }
                }
            );
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        setLoading(false);
    };


    return (
        <>
            
                <section>
                    <div className="text-white bg-gradient section-header">
                        <Container className="py-1">
                            <Stack direction="horizontal" gap={2} className="flex-wrap py-3 py-md-4">
                                <h1 className="fw-medium mb-0 me-auto">Last Words</h1>
                                <div className="search-form">
                                    <InputText
                                        controlId="Search"
                                        placeholder="Search"
                                        inputClassName="border-0"
                                        value={searchTerm}
                                        handleChange={(event: any) => {
                                            setSearchTerm(event.target.value);
                                            setPageNumber(1); // Reset the page number when the search term changes
                                        }}
                                        leftIcon={<IoIosSearch size={21.5} />}
                                    />

                                </div>
                            </Stack>
                        </Container>
                    </div>
                    {data && totalPage ?                   
                    <div className="section-content py-4">
                        <Container className="py-2 mb-4 mb-xl-5">
                            <InfiniteScroll
                                dataLength={data.length} // The length of the data array
                                next={() => { setPageNumber(pageNumber + 1) }} // Function to load more data
                                hasMore={pageNumber < totalPage} // Whether there is more data to load
                                loader={<Loader isLoading={loading} />} // Optional loader component to display while loading more data
                                //endMessage={<div className ="col-12"><div className="alert  bg-light text-center">No more data to load</div></div>} // Message to display when all data is loaded
                                className="row g-4 g-xl-5"
                            >
                                {data.filter(
                                    (item, index, self) =>
                                        index === self.findIndex((t) => t.id === item.id)
                                ).map((data: any, index: any) => {
                                    const { id, asset_url_name, proclamation_title, thumb_asset_url_name, proclamation_user_name } = data;
                                    const year: any = data.proclamation_user_legacy_released_date ? moment(data.proclamation_user_legacy_released_date).format('DD MMMM, YYYY') : '';
                                    const message: any = data.message ? data.message : '';
                                    const GoToView = () => {
                                        navigate(`/last-words/${id}`);
                                        window.scrollTo({
                                            top: 0,
                                            behavior: 'smooth'
                                        });
                                    }
                                    return (
                                        <Col key={index} md={6}>
                                            <Card onClick={GoToView} role="button" className="border-0 p-3 p-md-4 rounded-4 shadow h-100">
                                                <div className="d-flex flex-column h-100 p-xl-2">
                                                    <Ratio
                                                        aspectRatio={70}
                                                        role="button"
                                                    >
                                                        <div className="bg-black rounded-6">
                                                            <Card.Img variant="top" className="w-100 h-100 opacity-90 rounded-6 object-fit-cover" src={thumb_asset_url_name} width={500} height={350} alt={`Image of ${proclamation_title}`} />
                                                            <span className="d-inline-flex position-absolute rounded-circle shadow-lg start-50 text-white top-50 translate-middle">
                                                                {SvgIcons.playIcon}
                                                            </span>
                                                        </div>
                                                    </Ratio>

                                                    <Card.Body className="card-body pb-0 px-0 mt-1 d-flex flex-column">
                                                        <h4 className="h4 text-primary fw-bold mb-3 pb-1">{truncateTitle(proclamation_title)}</h4>
                                                        <Card.Subtitle className="fw-medium mb-3 pb-1 text-gray-light">
                                                            {proclamation_user_name}{data.created_at ? <span className="px-1 d-none d-sm-inline-block">|</span> : null}  <div className="d-block d-sm-inline-block text-nowrap mt-2 mt-sm-0">Posted on {year}</div>
                                                        </Card.Subtitle>
                                                        <Card.Text className="lh-lg mb-3">
                                                            {truncateText(message)}
                                                        </Card.Text>
                                                        <div className="mt-auto">
                                                            <Button variant="primary" className="mw-180">
                                                                <span className="display-4">View details</span><IoMdArrowDroprightCircle size={22.5} className="ms-2" />
                                                            </Button>
                                                        </div>
                                                    </Card.Body>
                                                </div>
                                            </Card>
                                        </Col>
                                    );
                                })}
                            </InfiniteScroll>
                        </Container>
                    </div>
                : <Container className="py-4"><div className="my-2 alert bg-light text-center">No data found</div></Container>}
                </section>
                
            <Loader isLoading={loadingsearch} />
        </>
    )
}
