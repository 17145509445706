import { Button, Card, Col, Container, ListGroup, Nav, Row } from 'react-bootstrap'
import { BsCheck2Circle } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';


export const PricingSection = (getData: any) => {
    const pricingData = getData.planData;
    return (
        <>
            {pricingData ?

                <section id="pricing" className="py-3 pricing-section">
                    <Container className="my-4 my-xl-5 pt-3">
                        <h2 className="display-2 fw-900 text-uppercase mb-1 text-white text-center">pricing</h2>
                        <p className="mb-0 text-white text-center">Choose the plan that suits you best.</p>
                        <Row className="gx-xl-5 justify-content-center">
                            {pricingData.map((data: any) => {
                                const { id, plan_name, plan_price, allowed_recipient, allowed_proclamation, video_length } = data
                                const selectedPlan = () => {

                                }
                                return (
                                    <Col key={id} md={6} lg={4} className="mt-4 py-lg-3">
                                        <Card className="border-0 rounded-4 p-3 p-md-4 h-100">
                                            <Card.Title className="display-3 fw-semibold text-center mt-3 mb-0">{plan_name}</Card.Title>
                                            <Card.Title className="display-3 text-uppercase fw-bold text-secondary text-center mb-0">{plan_price > 1 ? '$' + plan_price : 'FREE'}</Card.Title>
                                            <ListGroup as="ul" variant="flush" className="py-4 mt-3 mb-1">
                                                <ListGroup.Item key={`item-1-${id}`} as="li" className="align-items-center border-0 d-flex pt-0 px-0 mb-xl-3 pb-3 display-4 text-black">
                                                    <span><BsCheck2Circle size={20} className="text-primary me-3" /></span>
                                                    <div>{plan_name == 'Basic' ? 'No payment':'One time payment'}</div>
                                                </ListGroup.Item>
                                                <ListGroup.Item key={`item-1-${id}`} as="li" className="align-items-center border-0 d-flex pt-0 px-0 mb-xl-3 pb-3 display-4 text-black">
                                                    <span><BsCheck2Circle size={20} className="text-primary me-3" /></span>
                                                    <div>Up to {allowed_recipient} Recipients</div>
                                                </ListGroup.Item>
                                                <ListGroup.Item key={`item-2-${id}`} as="li" className="align-items-center border-0 d-flex pt-0 px-0 mb-xl-3 pb-3 display-4 text-black">
                                                    <span><BsCheck2Circle size={20} className="text-primary me-3" /></span>
                                                    <div>Up to {allowed_proclamation} Public Proclamation</div>
                                                </ListGroup.Item>
                                                <ListGroup.Item key={`item-3-${id}`} as="li" className="align-items-center border-0 d-flex pt-0 px-0 mb-xl-3 pb-3 display-4 text-black">
                                                    <span><BsCheck2Circle size={20} className="text-primary me-3" /></span>
                                                    <div>Up to {video_length} Seconds Video</div>
                                                </ListGroup.Item>
                                            </ListGroup>
                                            <div className="text-center mb-3 mt-auto">
                                                <HashLink className="fs-15 mw-150 btn btn-primary btn-sm"
                                                    to="/home#share-memo">Get Started</HashLink>
                                            </div>
                                        </Card>
                                    </Col>
                                )
                            })}
                        </Row>
                    </Container>
                </section>

                : ''}
        </>

    )
}
