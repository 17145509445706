import { MainLayout } from "../components/layouts/MainLayout";
import { LastWords } from "../pages/LastWords/routes";
import { About } from "../pages/about/About";
import { Faq } from "../pages/faq/Faq";
import { Home } from "../pages/home/Home";
import { NotFound } from "../pages/notFound/NotFound";
import { PrivacyPolicy } from "../pages/privacyPolicy/PrivacyPolicy";
import { Terms } from "../pages/terms/Terms";
import { Memory } from "../pages/memory/memory";
import { Delete } from "../pages/delete/Delete";

export const publicRoutes = [
  {
    path: '',
    element: <MainLayout />,
    children: [
      { path: '', element: <Home /> },
      { path: 'home', element: <Home /> },
      { path: 'last-words/*', element: <LastWords /> },
      { path: 'about', element: <About /> },
      { path: 'privacy-policy', element: <PrivacyPolicy /> },
      { path: 'terms', element: <Terms /> },
      { path: 'faq', element: <Faq /> },
      { path: '*', element: <NotFound /> },
    ]
  },
  { path: 'memory', element: <Memory /> },
  { path: 'delete', element: <Delete /> } 
  
];
export const nameBasedRoutes = {
  home: {
  name: "Home",
  path: `/`,
  },
  
};
