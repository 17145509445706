import { Card, Container, Ratio, Stack } from 'react-bootstrap';
import { LastWordData } from '../../../dummyData/LastWordData';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { GetEntitiyByIDMethod } from '../../../helpers/helpersMethods';
import { getproclamationDetails } from '../../../services/homeService';
import { toast } from 'react-toastify';
import Loader from '../../../components/common/Loder';
import moment from 'moment';

export const ViewLastWords = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [details, setDetails] = useState<any>();
    
    useEffect(() => {
        setLoading(true);
        getproclamationDetails({id:id}).then(
          (resp) => {
            setLoading(false);
            if (resp && resp.data)
            {
                setDetails(resp.data);
                
            }
          },
          (error:any) => {
            setLoading(false);
            const err = error?.response?.data;
            if (err?.statusCode === 400) {
              toast.error(err.msg);
            }
          }
        );
      
      }, [id]);
    return (
        <>
        {!loading && details ?
        
        <section>
            <div className="text-white bg-gradient section-header">
                <Container className="py-1">
                    <Stack direction="horizontal" gap={2} className="flex-wrap py-3 py-md-4">
                        <h1 className="fw-medium mb-0 me-auto">Last Words</h1>
                    </Stack>
                </Container>
            </div>
            <div className="section-content py-4">
                <Container className="py-2">
                    <Card className="border-0">
                        <h4 className="text-primary fw-bold mb-4 pb-2">{details.proclamation_title}</h4>
                        <Ratio
                            aspectRatio={42.31}
                            role="button"
                        >
                          <video width="100%" height="100%" preload="auto" controls playsInline autoPlay className="object-fit-cover rounded-6 w-100 h-100" poster={details.thumb_asset_url_name} disablePictureInPicture controlsList="nodownload noplaybackrate" style={{ objectFit: "contain" }}>
                            <source src={details.asset_url_name} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                        </Ratio>
                        <Card.Body className="card-body pb-0 px-0 mt-2">
                            <Card.Subtitle className="fw-medium mb-3 text-gray-light">
                                Author: <span className="fw-semibold text-dark">{details.proclamation_user_name}</span>  {details.proclamation_user_legacy_released_date ? <span className="px-2 d-none d-sm-inline-block">|</span> : null}  
                                <div className="d-block d-sm-inline-block text-nowrap mt-2 mt-sm-0">Created on <span className="fw-semibold text-dark">{details.created_at ? moment(details.created_at).format('DD MMMM, YYYY'):''} <span className="px-2 d-none d-sm-inline-block">|</span></span></div>
                                <div className="d-block d-sm-inline-block text-nowrap mt-2 mt-sm-0">Posted on <span className="fw-semibold text-dark">{details.proclamation_user_legacy_released_date ? moment(details.proclamation_user_legacy_released_date).format('DD MMMM, YYYY'):''}</span></div>
                            </Card.Subtitle>
                            <Card.Text className="lh-lg mb-3">
                                {details.message}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Container>
            </div>
        </section>
        :''}
         <Loader isLoading={loading} />
        </>
    )
}
