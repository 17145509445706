import { Route, Routes } from 'react-router-dom';
import { AllLastWords } from './List/LastWords';
import { ViewLastWords } from './View/View';

export const LastWords = () => {
    return (
        <Routes>
            {/* <Route path="/" element={<AllProclamation />} /> */}
            <Route path="/" element={<AllLastWords />} />
            <Route path="/:id" element={<ViewLastWords  />} />
        </Routes>
    )
};