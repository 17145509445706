import { useRef } from 'react';
import { Footer } from './Footer/Footer'
import { Header } from './Header/Header'
import { Outlet } from "react-router";

export const MainLayout = () => {
  const parentScrollContainerRef = useRef<HTMLDivElement | null>(null);
  return (
    <main className="mainContentBox min-vh-100 w-100 d-flex flex-column" ref={parentScrollContainerRef}>
      <Header />
      <Outlet />
      <Footer />
    </main>
  )
}
