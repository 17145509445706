import { useState, useContext, useEffect } from 'react'
import { Accordion, Card, Container, Stack, useAccordionButton } from 'react-bootstrap'
import AccordionContext from 'react-bootstrap/AccordionContext';
import { BsFillDashCircleFill, BsPlusCircleFill } from 'react-icons/bs';
import { toast } from 'react-toastify';
import Loader from '../../components/common/Loder';
import { getFaqList } from '../../services/homeService';

export const Faq = () => {
    const [activeKey, setActiveKey] = useState('0');
    const [loading, setLoading] = useState(false);
    const [faqList, setFaqList] = useState([]);
    useEffect(() => {
        setLoading(true);
        getFaqList().then(
          (resp) => {
            setLoading(false);
            setFaqList(resp?.data.faq_list);
          
            
          },
          (error:any) => {
            setLoading(false);
            const err = error?.response?.data;
            if (err?.statusCode === 400) {
              toast.error(err.msg);
            }
          }
        );
      
      }, []);
  

    const handleAccordionToggle = (eventKey: any) => {
        setActiveKey(eventKey ?? '');
    };



    function ContextAwareToggle({ children, eventKey, callback }: any) {
        const { activeEventKey }: any = useContext(AccordionContext);

        const decoratedOnClick = useAccordionButton(
            eventKey,
            () => callback && callback(eventKey),
        );

        const isCurrentEventKey = activeEventKey === eventKey;

        return (
            <h5
                role="button"
                className={`d-flex fw-semibold py-2 py-md-3 mb-1 user-select-none ${isCurrentEventKey ? 'text-primary' : 'text-dark'}`}
                onClick={decoratedOnClick}
            >
                <span className="d-flex align-items-center me-3">{isCurrentEventKey ? <BsFillDashCircleFill /> : <BsPlusCircleFill />}</span>
                {children}
            </h5>
        );
    }

    return (
       <>
         {faqList ? 
        <section>
            <div className="text-white bg-gradient section-header">
                <Container className="py-1">
                    <Stack direction="horizontal" gap={2} className="flex-wrap py-3 py-md-4">
                        <h1 className="fw-medium mb-0 me-auto">FAQ</h1>
                    </Stack>
                </Container>
            </div>
            <div className="section-content py-4">
                <Container className="py-2 lh-lg">
                    <Accordion
                        activeKey={activeKey}
                        onSelect={handleAccordionToggle}
                        defaultActiveKey="0"
                        flush
                    >
                        {faqList.map((item:any, index:any) => (
                            <Accordion.Item key={index} eventKey={index.toString()}>
                                <ContextAwareToggle eventKey={index.toString()}>{item.question}</ContextAwareToggle>
                                <Accordion.Collapse eventKey={index.toString()}>
                                    <Card.Body>
                                        <div className="pb-3 d-flex display-4 lh-base section-content-editor">
                                            <span className="d-flex align-items-center me-3 invisible"><BsFillDashCircleFill size={20} /></span>
                                            {item.answer}
                                        </div>
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Accordion.Item>
                        ))}

                    </Accordion>
                </Container>
            </div>
            <Loader isLoading={loading} />
        </section>
         
        :''}
        </>
        
    )
}
