import { FC } from 'react';
import Form from 'react-bootstrap/Form';

interface ICustomTextArea {
    controlId?: string;
    size?: any;
    placeholder?: string;
    handleChange?: any;
    handleBlur?: any;
    errorsField?: any;
    touched?: any;
    value?: any;
    className?:any;
}

/**
 * Textarea Reusable Component
 * @date 4/25/2023 - 5:58:45 PM
 *
 * @param {{ controlId: any; size: any; placeholder: any; handleChange: any; handleBlur: any; errorsField: any; touched: any; value: any; className: any; }} {
    controlId, size, placeholder, handleChange, handleBlur, errorsField, touched, value, className
}
 * @returns {*}
 */

const CustomTextArea: FC<ICustomTextArea> = ({
    controlId, size, placeholder, handleChange, handleBlur, errorsField, touched, value, className
}) => {
    return (
        <Form.Group className={className} controlId={controlId}>
            <Form.Control as="textarea" rows={5} value={value} placeholder={placeholder} onBlur={handleBlur} onChange={handleChange} isInvalid={!!errorsField && touched} size={size} />
            <Form.Control.Feedback type="invalid">
                {errorsField}
            </Form.Control.Feedback>
        </Form.Group>
    );
}

export default CustomTextArea