import { Container, Stack } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import { FaFacebookF, FaLinkedin, FaTwitter } from "react-icons/fa";

export const Footer = () => {
  const getCurrentYear = new Date().getFullYear();
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }
  const facebookLink = 'https://www.facebook.com/profile.php?id=100090332070435';
  const twitterLink = 'https://twitter.com/tweets_mylo';
  const linkedinLink = 'https://www.linkedin.com/company/project-mylo/about/?viewAsMember=true';

  return (
    <footer className="bg-dark text-center text-white mt-auto py-4">
      <Container>
        <Stack direction="horizontal" gap={3} className="mb-3 pb-1 justify-content-center">
          <Link to={facebookLink} target="_blank" className="text-light px-1">
            <FaFacebookF size={18} />
          </Link>
          <Link to={twitterLink} target="_blank" className="text-light px-1">
            <FaTwitter size={19} />
          </Link>
          <Link to={linkedinLink} target="_blank" className="text-light px-1">
            <FaLinkedin size={18} />
          </Link>
        </Stack>
        <Stack direction="horizontal" className="pb-1 justify-content-center lh-sm footer-links flex-wrap">
          <NavLink onClick={scrollToTop} to="/" className="text-white text-decoration-none mb-3">Home</NavLink>
          <NavLink onClick={scrollToTop} to="/about" className="text-white text-decoration-none mb-3">About</NavLink>
          <NavLink onClick={scrollToTop} to="/last-words" className="text-white text-decoration-none mb-3">Last Words</NavLink>
          <NavLink onClick={scrollToTop} to="/privacy-policy" className="text-white text-decoration-none mb-3">Privacy Policy</NavLink>
          <NavLink onClick={scrollToTop} to="/terms" className="text-white text-decoration-none mb-3">Terms of Use</NavLink>
          <NavLink onClick={scrollToTop} to="/faq" className="text-white text-decoration-none mb-3">FAQ</NavLink>
        </Stack>
        <div className="fs-13">Copyright &copy; {getCurrentYear}. All Rights Reserved.</div>
      </Container>
    </footer>
  )
}
