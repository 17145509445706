import { Container, Stack } from "react-bootstrap"
import { FaExclamationTriangle } from "react-icons/fa"
import { IoMdArrowDroprightCircle } from "react-icons/io"
import { Link } from "react-router-dom"

export const NotFound = () => {
  return (
    <section className="d-flex flex-column flex-grow-1">
      <div className="text-white bg-gradient section-header">
        <Container className="py-1">
          <Stack direction="horizontal" gap={2} className="flex-wrap py-3 py-md-4">
            <h1 className="fw-medium mb-0 me-auto">Error 404</h1>
          </Stack>
        </Container>
      </div>
      <Container className="py-4 my-auto">
        <div className="col-lg-8 m-auto text-center">
          <FaExclamationTriangle size="4rem" className="text-danger" />
          <h1 className="fw-semibold my-3">Oops! Page not found</h1>
          <p className="display-4 lh-lg text-muted">Sorry, the page you're looking for&nbsp;isn't&nbsp;here. <br />We suggest checking the URL for typos or navigating back to&nbsp;our&nbsp;homepage.</p>
          <div className="d-inline-flex gap-2 mb-5">
            <Link to="/" className="btn btn-primary mw-170">
              <span className="display-4">Back to homepage </span><IoMdArrowDroprightCircle size={22.5} className="ms-2" />
            </Link>
          </div>
        </div>
      </Container>
    </section>
  )
}