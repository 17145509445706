import {axios} from "../services/axios";
import { adminApi } from "../api";


export type HomeDTO = {
  title: string
};
export type contactDTO = {
  name: string;
  email: string;
  subject: string;
  message: string;
};


/**
 * Faq List
 * @date 04/05/2023 - 13:21:19
 *
 * @async
 * @param {*} params
 * @returns {unknown}
 */
export const getHome = async () => {
    return await axios.get(`${adminApi.home}`);
  };
/**
 * Get FAQ LIST
 * @date 14/06/2023 - 15:19:43
 *
 * @async
 * @returns {unknown}
 */
export const getFaqList = async () => {
    return await axios.get(`${adminApi.faq}`);
  };
/**
 * Get Pages
 * @date 14/06/2023 - 16:32:48
 *
 * @async
 * @returns {unknown}
 */
export const getPages = async (params:any) => {
    return await axios.get(`${adminApi.cms}`,{params});
  };

  /**
   * proclamation list
   * @date 15/06/2023 - 11:00:34
   *
   * @async
   * @param {*} params
   * @returns {unknown}
   */
  export const getproclamationList = async (params:any) => {
  
    return await axios.get(`${adminApi.proclamation}`,{params});
  };
  
  export const getproclamationDetails = async (params:any) => {
    return await axios.get(`${adminApi.proclamationDetails}`,{params});
  };

  /**
   * contact us
   * @date 16/06/2023 - 19:39:57
   *
   * @async
   * @param {string} id
   * @param {contactDTO} data
   * @returns {Promise<any>}
   */
  export const contact = async (data: contactDTO): Promise<any> => {
    return axios.put(`${adminApi.contact}`,data);
  };