import { FC } from 'react';
import Form from 'react-bootstrap/Form';

interface IInputText {
    controlId: string;
    size?: any;
    placeholder?: string;
    handleChange?: any;
    handleBlur?: any;
    errorsField?: any;
    touched?: any;
    value?: any;
    className?: any;
    inputClassName?: any;
    leftIcon?: any;
}

/**
 * Input Type Text Reusable Component
 * @date 4/25/2023 - 6:12:55 PM
 *
 * @param {{ controlId: any; size: any; placeholder: any; handleChange: any; handleBlur: any; errorsField: any; touched: any; value: any; className: any; inputClassName: any; leftIcon: any; }} {
    controlId, size, placeholder, handleChange, handleBlur, errorsField, touched, value, className, inputClassName, leftIcon
}
 * @returns {*}
 */

const InputText: FC<IInputText> = ({
    controlId, size, placeholder, handleChange, handleBlur, errorsField, touched, value, className, inputClassName, leftIcon
}) => {
    return (
        <Form.Group className={`position-relative ${className || ''} ${leftIcon ? 'left-icon-active' : ''}`}
            controlId={controlId}>
            <Form.Control className={inputClassName} value={value} placeholder={placeholder} onBlur={handleBlur} onChange={handleChange} isInvalid={!!errorsField && touched} size={size} />
            {leftIcon ?
                <span className="left-icon position-absolute start-0 text-gray top-0 pt-1 ps-1 ms-1">{leftIcon}</span>
                : null}
            <Form.Control.Feedback type="invalid">
                {errorsField}
            </Form.Control.Feedback>
        </Form.Group>
    );
}

export default InputText