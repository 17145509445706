import { Carousel, Col, Container, Image, Stack } from 'react-bootstrap'
import { IoMdArrowDroprightCircle } from 'react-icons/io'
import { Link } from 'react-router-dom';
import AppStore from '../../assets/images/app-store.svg';
import GooglePlay from '../../assets/images/google-play.svg';
const BannerVideo1 = require('../../assets/videos/hp-banner-1.mp4');
const BannerVideo2 = require('../../assets/videos/hp-banner-2.mp4');
const BannerVideo3 = require('../../assets/videos/hp-banner-3.mp4');

export const HomeBanner = () => {
    const GooglePlayUrl = 'https://play.google.com/store/apps/details?id=com.mylo.mylifeonline';
    const AppStoreURL = 'https://apps.apple.com/us/app/my-life-online/id6450709974';
   
    return (
        <section id="home" className="bg-black text-white overflow-hidden position-relative theme-home-banner">
            <Carousel
                fade={true}
                controls={false}
                indicators={false}
                pause={false}
                touch={true}
                interval={6000}
            >
                <Carousel.Item>
                    <div className="align-items-center d-flex h-100 position-absolute start-0 theme-home-banner-content-box top-0 w-100 z-1">
                        <Container>
                            <Col md={8} xl={7}>
                                <h1 className="display-1 mb-md-3 pb-1">Meet MYLO - <span className="text-secondary fw-bold">The Digital Safe for Your Legacy &nbsp;</span><br /></h1>
                                <h4 className="mb-md-3 pb-md-2">A new app built to securely house your will or assets, as well as leave your  <span className="text-primary fw-bold">last words</span></h4>
                                <Stack direction="horizontal" gap={4}>
                                    <Link to={AppStoreURL} target="_blank">
                                        <Image className="img-fluid" src={AppStore} alt="App Store" width={177} height={58} />
                                    </Link>
                                    <Link to={GooglePlayUrl} target="_blank">
                                        <Image className="img-fluid" src={GooglePlay} alt="Google Play" width={202} height={60} />
                                    </Link>
                                </Stack>
                            </Col>
                        </Container>
                    </div>
                    <video width="100%" height="100%" preload='auto' playsInline autoPlay loop muted className="opacity-50 object-fit-cover w-100 h-100">
                        <source src={BannerVideo1} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </Carousel.Item>
                <Carousel.Item>
                    <div className="align-items-center d-flex h-100 position-absolute start-0 theme-home-banner-content-box top-0 w-100 z-1">
                        <Container>
                            <Col md={8} xl={7}>
                            <h1 className="display-1 mb-md-3 pb-1">Meet MYLO - <span className="text-secondary fw-bold">The Digital Safe for Your Legacy &nbsp;</span><br /></h1>
                                <h4 className="mb-md-3 pb-md-2">A new app built to securely house your will or assets, as well as leave your  <span className="text-primary fw-bold">last words</span></h4>
                                <Stack direction="horizontal" gap={4}>
                                    <Link to={AppStoreURL} target="_blank">
                                        <Image className="img-fluid" src={AppStore} alt="App Store" width={177} height={58} />
                                    </Link>
                                    <Link to={GooglePlayUrl} target="_blank">
                                        <Image className="img-fluid" src={GooglePlay} alt="Google Play" width={202} height={60} />
                                    </Link>
                                </Stack>
                            </Col>
                        </Container>
                    </div>
                    <video width="100%" height="100%" preload='auto' playsInline autoPlay loop muted className="opacity-50 object-fit-cover w-100 h-100">
                        <source src={BannerVideo2} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </Carousel.Item>
                <Carousel.Item>
                    <div className="align-items-center d-flex h-100 position-absolute start-0 theme-home-banner-content-box top-0 w-100 z-1">
                        <Container>
                            <Col md={8} xl={7}>
                            <h1 className="display-1 mb-md-3 pb-1">Meet MYLO - <span className="text-secondary fw-bold">The Digital Safe for Your Legacy &nbsp;</span><br /></h1>
                                
                                <h4 className="mb-md-3 pb-md-2">A new app built to securely house your will or assets, as well as leave your  <span className="text-primary fw-bold">last words</span></h4>
                                <Stack direction="horizontal" gap={4}>
                                    <Link to={AppStoreURL} target="_blank">
                                        <Image className="img-fluid" src={AppStore} alt="App Store" width={177} height={58} />
                                    </Link>
                                    <Link to={GooglePlayUrl} target="_blank">
                                        <Image className="img-fluid" src={GooglePlay} alt="Google Play" width={202} height={60} />
                                    </Link>
                                </Stack>
                            </Col>
                        </Container>
                    </div>
                    <video width="100%" height="100%" preload='auto' playsInline autoPlay loop muted className="opacity-50 object-fit-cover w-100 h-100">
                        <source src={BannerVideo3} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </Carousel.Item>
            </Carousel>


        </section >
    )
}