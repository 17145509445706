export const NavItems = [
    {
        id: 'home',
        menuName: 'Home',
        path: '/',
    },
    {
        id: 'about',
        menuName: 'About',
        path: '/about',
    },
    {
        id: 'proclamation',
        menuName: 'Last Words',
        path: '/last-words',
    },
    // {
    //     id: 'pricing',
    //     menuName: 'Pricing',
    //     path: '/home#pricing',
    // },
    // {
    //     id: 'contact',
    //     menuName: 'Contact',
    //     path: '/home#contact',
    // }
]