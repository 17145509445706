import { Formik } from 'formik';
import React, { useRef, useState } from 'react'
import { Button, Col, Container, Form, Image, Row, Stack } from 'react-bootstrap'
import { ContactSchema } from '../../validations/Contact';
import InputText from '../../components/common/InputText';
import CustomTextArea from '../../components/common/CustomTextArea';
import ReCAPTCHA from 'react-google-recaptcha';
import { AddEntitiyMethod } from '../../helpers/helpersMethods';
import { contact } from '../../services/homeService';
import { useNavigate } from 'react-router-dom';
import { CONTACT_SEND_SUCCESS } from '../../validations/ValidationErrors';
import { nameBasedRoutes } from '../../router/public';
import Loader from '../../components/common/Loder';


export const ContactSection = () => {
    const initialValues = {
        name: '',
        email: '',
        subject: '',
        message: ''
    };
    const [captchaCode, setCaptchaCode] = useState("");
    const recaptchaRef = useRef<any>(null);
    const cpatchaKey: string | any = process.env.REACT_APP_CPATCHA_KEY;
    const [btnStatus, setBtnStatus] = useState(true);
    const navigate = useNavigate();
    const [redirect, setRedirect] = useState<any>();
    const [submitting, setSubmitting] = useState(false);
    const [loading, setLoading] = useState(false);
  
    const onChangeCaptchaCode = (value: any) => {
        setCaptchaCode(value);
        setBtnStatus(false);
    };

    const submitForm = (value: any,actions: any) => {
        if (value)
        {
          AddEntitiyMethod(setSubmitting, contact, value, CONTACT_SEND_SUCCESS, nameBasedRoutes.home.path, navigate);
        }
        setCaptchaCode("");
        actions.resetForm();
        recaptchaRef.current?.reset();
        
    }
    return (
        <>{!loading  ? 
        <Formik
            validationSchema={ContactSchema}
            initialValues={initialValues}
            onSubmit={(values, actions) => {
                values.name = values.name.trim();
                values.email = values.email.trim();
                values.subject = values.subject.trim();
                values.message = values.message.trim();
                actions.setSubmitting(false);
                submitForm(values,actions)
              }}



        >
            {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
            }) => (
                <section id="contact" className="py-3 contact-section">
                    <Container className="my-4 my-xl-5 pt-3">
                        <h2 className="display-2 fw-900 text-uppercase mb-1 text-black text-center">Get in Touch</h2>
                        <p className="mb-0 text-center">We'd love to hear from you.</p>
                        <Form className="pt-4 mt-3 mx-auto" noValidate onSubmit={handleSubmit}>
                            <Row>
                                <Col lg>
                                    <InputText
                                        controlId="name"
                                        placeholder="Name"
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        touched={touched.name}
                                        errorsField={errors.name}
                                        value={values.name}
                                        size="lg"
                                        className="mb-3 pb-1"
                                    />
                                    <InputText
                                        controlId="email"
                                        placeholder="Email"
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        touched={touched.email}
                                        errorsField={errors.email}
                                        value={values.email}
                                        size="lg"
                                        className="mb-3 pb-1"
                                    />
                                    <InputText
                                        controlId="subject"
                                        placeholder="Subject"
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        touched={touched.subject}
                                        errorsField={errors.subject}
                                        value={values.subject}
                                        size="lg"
                                        className="mb-3 pb-1"
                                    />
                                </Col>
                                <Col lg>
                                    <CustomTextArea
                                        controlId="message"
                                        placeholder="Message"
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        touched={touched.message}
                                        errorsField={errors.message}
                                        value={values.message}
                                        size="lg"
                                        className="mb-3 pb-1"
                                    />
                                    <Stack direction="horizontal" gap={3} className="mb-3 pb-1 align-items-start flex-column flex-sm-row gap-3">
                                        <div className=" p-1 bg-white captcha-main d-flex align-items-center justify-content-center">
                                            <ReCAPTCHA
                                                ref={recaptchaRef}
                                                sitekey={cpatchaKey}
                                                onChange={onChangeCaptchaCode}
                                                className='recaptcha overflow-x-auto overflow-y-hidden'
                                            />
                                        </div>
                                        <Button type="submit" variant="primary" size="lg" className="fs-15 mw-150 ms-sm-auto" disabled={btnStatus}>Submit</Button>
                                    </Stack>
                                </Col>
                            </Row>

                        </Form>
                    </Container>
                </section>
            )}
         </Formik > : ''}
      <Loader isLoading={loading || submitting} />
        </>
    )
}
