import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./router";
import { ToastContainer } from 'react-toastify';

function App() {
  const baseUrl: any = process.env.REACT_APP_BASE_NAME;
  return (

    <React.Suspense>

      <Router basename={baseUrl}>
        <AppRoutes />
        <ToastContainer limit={1}/>
      </Router>
    </React.Suspense>


  );
}

export default App;
