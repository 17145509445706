import { Col, Container, Image, Row, Stack } from "react-bootstrap"
import { Link } from "react-router-dom"
import AppStore from '../../assets/images/app-store.svg';
import GooglePlay from '../../assets/images/google-play.svg';
import AppShareMobile from '../../assets/images/share-mobile.png';

export const ShareSection = () => {
    const GooglePlayUrl = 'https://play.google.com/store/apps/details?id=com.mylo.mylifeonline';
    const AppStoreURL = 'https://apps.apple.com/us/app/my-life-online/id6450709974';
    return (
        <section id="share-memo" className="bg-gradient overflow-hidden text-white pt-4 pt-md-0">
            <Container>
                <Row className="align-items-center">
                    <Col md>
                        <div className="fs-14 fst-italic">Download for free</div>
                        <h2 className="display-2 fw-900 text-uppercase mb-1">Securely Store Your Will and Assets</h2>
                        <p className="fs-14 mb-4 pb-3">Share your Legacy and leave a Lasting Impact!</p>
                        <Stack direction="horizontal" gap={4}>
                            <Link to={AppStoreURL} target="_blank">
                                <Image className="img-fluid" src={AppStore} alt="App Store" width={177} height={58} />
                            </Link>
                            <Link to={GooglePlayUrl} target="_blank">
                                <Image className="img-fluid" src={GooglePlay} alt="Google Play" width={202} height={60} />
                            </Link>
                        </Stack>
                    </Col>
                    <Col md={5} lg={6} xl="auto">
                        <div className="share-section-height pt-3">
                            <Image className="img-fluid mt-3 share-mobile-image" src={AppShareMobile} alt="App Store" width={453} height={560} />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
