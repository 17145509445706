import { Card, Container, Ratio, Stack } from "react-bootstrap";
export const Delete = () => {
  return (
    <>
      <section>
     
        <div className="section-content py-4">
          <Container className="py-2">
          <h1 className="merorypadding">How to delete account? </h1>
      <p>Here are the steps to be followed within the application to delete your account.</p>


      <p>
        <h2 className="memoryH2">Step 1 </h2>
        <p>Login to the application.</p>
      </p>

       <p>
        <h2 className="memoryH2">Step 2</h2>
        <p>Click on "Hamburger" icon on topmost left hand side and select "Profile" option.</p>
       </p>

      <p>
        <h2 className="memoryH2">Step 3</h2>
        <p>Click on "Delete Account" option.</p>
      </p>

      <p>
        <h2 className="memoryH2">Step 4</h2>
        <p>Click on Confirm to proceed further to enter the Password.</p>
      </p>

      <p>
        <h2 className="memoryH2">Step 5</h2>
        <p>Enter the Password and select "Submit".</p>
      </p>

      <p>
         <h1 className="merorypadding">What data will be deleted?</h1>
            <p>We will delete all the data associalted with the account.</p>
      </p>
          </Container>
        </div>
      </section>
    </>
  );
};
