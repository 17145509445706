import React, { useEffect, useState } from 'react'
import { Container, Stack } from 'react-bootstrap'
import { toast } from 'react-toastify';
import Loader from '../../components/common/Loder';
import { getPages } from '../../services/homeService';

export const Terms = () => {
    const [loading, setLoading] = useState(false);
    const [details, setDetails] = useState<any>();

    useEffect(() => {
        setLoading(true);
        getPages({slug:'terms_of_use'}).then(
          (resp) => {
            setLoading(false);
            setDetails(resp?.data.page_detail);
         
          },
          (error:any) => {
            setLoading(false);
            const err = error?.response?.data;
            if (err?.statusCode === 400) {
              toast.error(err.msg);
            }
          }
        );
      
      }, []);
    return (
        <>
        {details ?
        <section>
            <div className="text-white bg-gradient section-header">
                <Container className="py-1">
                    <Stack direction="horizontal" gap={2} className="flex-wrap py-3 py-md-4">
                        <h1 className="fw-medium mb-0 me-auto">Terms of Use</h1>
                    </Stack>
                </Container>
            </div>
            <div className="section-content pb-4 pt-2 section-content-editor">
                <Container className="py-2 lh-lg" dangerouslySetInnerHTML={{ __html: details.description }}>
                </Container>
            </div>
        </section>
         :''}
         <Loader isLoading={loading} />
         </>
    )
}
