import moment from 'moment'
import { useEffect, useState } from 'react'
import { Card, Col, Container, Ratio, Row } from 'react-bootstrap'
import { IoMdArrowDroprightCircle } from 'react-icons/io'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { SvgIcons } from '../../components/common/SvgIcons'
import { LastWordData } from '../../dummyData/LastWordData'
import { truncateTitle } from '../../helpers/helpersMethods'


export const ProclamationsSection = (dataList: any) => {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }
    const datas = dataList.proclamations ? dataList.proclamations : [];
    const navigate = useNavigate();
    const [data, setData] = useState<any[]>(datas);



    return (
        <>
            {data ?
                <section id="proclamation" className="py-3 text-center proclamation-section">
                    <Container className="mt-4 mt-xl-5 mb-4">
                        <h2 className="display-2 fw-900 text-uppercase mb-0 text-black pt-3">TRANSFORMATIVE JOURNEYS</h2>
                        <p className="mb-0">Create a legacy that lives on forever.</p>
                        <Row className='justify-content-center mb-lg-0 mb-4 pb-lg-0 pb-2'>
                            {data.slice(0, 3).map((data: any) => {
                                const { id, proclamation_title, thumb_asset_url_name, proclamation_user_name, message } = data;
                                const year: any = data.proclamation_user_legacy_released_date ? moment(data.proclamation_user_legacy_released_date).format('DD MMMM, YYYY') : '';
                                const GoToView = () => {
                                    navigate(`/last-words/${id}`);
                                    window.scrollTo({
                                        top: 0,
                                        behavior: 'smooth'
                                    });
                                }
                                return (
                                    <Col key={id} md={6} lg={4} className="mt-4 pt-2 my-lg-4 py-lg-3">
                                        <Card onClick={GoToView} role="button" className="border-0">
                                            <Ratio
                                                aspectRatio={81}
                                                role="button"
                                            >
                                                <div className="bg-black rounded-10">
                                                    <Card.Img variant="top" className="w-100 h-100 opacity-75 rounded-10 object-fit-cover" src={thumb_asset_url_name} width={370} height={300} alt={`Image of ${proclamation_title}`} />
                                                    <span className="d-inline-flex position-absolute rounded-circle shadow-lg start-50 text-white top-50 translate-middle">
                                                        {SvgIcons.playIcon}
                                                    </span>
                                                </div>
                                            </Ratio>
                                            <Card.Body className="card-body pb-0 px-0">
                                                <Card.Title className="text-black fw-bold mb-1"> {truncateTitle(proclamation_title)}</Card.Title>
                                                <Card.Text className="fw-semibold">
                                                    {proclamation_user_name}  {year ? <span className="px-1">|</span> : null}  {year}
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                )
                            })}
                        </Row>
                        <Link onClick={scrollToTop} to="/last-words" className="btn btn-primary mw-170 view_more_margin">
                            <span className="display-4">View more </span><IoMdArrowDroprightCircle size={22.5} className="ms-2" />
                        </Link>
                    </Container>
                </section>
                : <div>No data found</div>}
        </>
    )
}
