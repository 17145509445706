import { HomeBanner } from './HomeBanner';
import { AboutSection } from './AboutSection';
import { ShareSection } from './ShareSection';
import { ProclamationsSection } from './ProclamationsSection';
import { PricingSection } from './PricingSection';
import { ContactSection } from './ContactSection';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { getHome } from '../../services/homeService';
import Loader from '../../components/common/Loder';

export const Home = () => {

  const [loading, setLoading] = useState(false);
  const [plan, setPlan] = useState(null);
  const [whatMyloDoes, setWhatMyloDoes] = useState(null);
  const [proclamations, setProclamations] = useState(null);
  useEffect(() => {
    setLoading(true);
    getHome().then(
      (resp) => {
        setLoading(false);
        setPlan(resp?.data.plans);
        setWhatMyloDoes(resp?.data.what_mylo_does);
        setProclamations(resp?.data.proclamations);
        
      },
      (error:any) => {
        setLoading(false);
        const err = error?.response?.data;
        if (err?.statusCode === 400) {
          toast.error(err.msg);
        }
      }
    );
  
  }, []);
  return (
    <>
    {plan && whatMyloDoes?
    <>
      <HomeBanner />
      <AboutSection data={whatMyloDoes}/>
      <ShareSection />
      <ProclamationsSection proclamations={proclamations}/>
      <PricingSection planData={plan}/>
      <ContactSection />
     
    </>
    
    :''}
     <Loader isLoading={loading} />
    </>
  )
}
