import Axios from 'axios';
import storage from '../helpers/storage';

/**
 * Axios Object to use 
 * @type {*}
 */
export const axios = Axios.create({
    baseURL: process.env.REACT_APP_API_URL
});
axios.interceptors.response.use(
    (response) => {
        return response.data;
    },
    (error) => {
      
        return Promise.reject(error);
    }
);
