import { Col, Container, Image, ListGroup, Row } from 'react-bootstrap'
import { BsCheck2Circle } from 'react-icons/bs'
import AboutImage from '../../assets/images/about-mylo.png';

export const AboutSection = (aboutData:any) => {
   
    const dataList:any = aboutData.data.description.split("&").map((data:any, index:any) => ({ id: index, value: data.trim() }));

    return (
        <>
         {aboutData && dataList ? 
        <section className="py-3 about-section">
            <Container className="my-4 my-xl-5">
                <Row>
                    <Col md={5} className="my-3">
                        <Image className="img-fluid" src={AboutImage} alt="Logo" width={430} height={430} />
                    </Col>
                  
                    <Col md={7} className="my-3">
                        <h2 className="display-3 fw-900 text-black text-uppercase mb-3 pb-1">What does <span className="text-secondary">MYLO</span> do?</h2>
                        <ListGroup as="ul" variant="flush">
                            {dataList.map((data: any) => {
                                const { id, value } = data;
                                return (
                                    <ListGroup.Item key={id} as="li" className="align-items-center border-0 d-flex pt-0 px-0 mb-xl-3 pb-3 display-4">
                                        <span><BsCheck2Circle size={20} className="text-primary me-3" /></span>
                                        <div>{value.replace(/<[^>]+>|amp;/g, '')}</div>
                                    </ListGroup.Item>
                                )
                            })}
                        </ListGroup>
                    </Col>
                </Row>
            </Container>
        </section>
         :''}
        </>
       
    )
}
