import { useRoutes } from "react-router-dom";
import { publicRoutes } from "./public";

/**
 * Main Router
 * @date 4/27/2023 - 11:08:21 AM
 *
 * @export
 * @returns {*}
 */

export default function AppRoutes() {
  const element = useRoutes([...publicRoutes]);
  return <>{element}</>;
}

