import { useEffect, useState } from "react";
import { Container, Image, Nav, Navbar, Offcanvas } from "react-bootstrap"
import { Link, NavLink, useLocation } from "react-router-dom"
import Logo from '../../../assets/images/logo.svg';
import LogoWhite from '../../../assets/images/logo-white.svg';
import { NavItems } from "./NavItems";
import { HashLink } from 'react-router-hash-link';

export const Header = () => {
  const expand = 'md'
  const location = useLocation();
  const [isScrolled, setIsScrolled] = useState(false);
  const [isHomepage, setIsHomepage] = useState(true);
  const [isPricingActive, setIsPricingActive] = useState(false);
  const [isContactActive, setIsContactActive] = useState(false);
  const [isNavbarVariant, setIsNavbarVariant] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const handleNavItemClick = () => {
    setExpanded(false);
  };

  
  useEffect(() => {
    if (location.pathname === '/' || location.pathname === '/home') {
      setIsHomepage(true);
      setIsScrolled(false);
    } else {
      setIsHomepage(false);
      setIsScrolled(true);
    }
    if (location.hash === '#pricing') {
      setIsHomepage(false);
      setIsPricingActive(true);
    } else {
      setIsPricingActive(false);
    }
    if (location.hash === '#contact') {
      setIsHomepage(false);
      setIsContactActive(true);
    } else {
      setIsContactActive(false);
    }
  }, [location.pathname, location.hash])

  useEffect(() => {
    function handleScroll() {
      const scrollTop = window.pageYOffset;
      if (scrollTop > 0 && !isScrolled) {
        setIsScrolled(true);
      } else if (scrollTop === 0 && isScrolled) {
        setIsScrolled(false);
      }
    }
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isScrolled, location.pathname]);

  // isScrolled isHomepage = true - white
  // !isScrolled isHomepage = false - transparent
  // isScrolled !isHomepage = true - white
  // !isScrolled !isHomepage = true - white

  useEffect(() => {
    if (!isScrolled && isHomepage) {
      setIsNavbarVariant(false);

    } else {
      setIsNavbarVariant(true)
    }
  }, [isScrolled, isHomepage])

  //scrollToTop on click
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  return (
    <Navbar expanded={expanded} bg={`${isNavbarVariant ? 'white' : 'transparent'}`} variant={`${isNavbarVariant ? 'light' : 'dark'}`} expand={expand} className={`theme-header-top fixed-top ${isNavbarVariant ? 'shadow-sm theme-header-scrolled-active' : ''}`}>
      <Container>
        <Navbar.Brand onClick={scrollToTop} as={Link} to="/">
          <Image className="img-fluid" src={isNavbarVariant ? Logo : LogoWhite} alt="Logo" width={160} height={60} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls={`navbarScroll-${expand}`}  onClick={() => setExpanded(!expanded)} />
        <Navbar.Collapse
          id={`navbarScroll-${expand}`}
          aria-labelledby={`navbarScroll-${expand}`}
        >
          <Nav navbarScroll defaultActiveKey="/" as="ul" className={`justify-content-end flex-grow-1 ${isHomepage ? 'homeActive' : ''}`}>
            {
              NavItems.map((elem: any) => {
                const { id, menuName, path } = elem;
                return (
                  <Nav.Item as="li" key={id} onClick={handleNavItemClick}>
                    <Nav.Link onClick={scrollToTop} as={NavLink} to={path} className="theme-header-nav-items align-items-md-center d-flex px-md-3">{menuName}
                    </Nav.Link>
                  </Nav.Item>
                )
              })
            }
            <Nav.Item as="li" onClick={handleNavItemClick}>
              <Nav.Link
                as={HashLink}
                to="/home#pricing"
                className={`theme-header-nav-items align-items-md-center d-flex px-md-3 ${isPricingActive ? 'active' : ''}`}>Pricing</Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" onClick={handleNavItemClick}>
              <Nav.Link as={HashLink} to="/home#contact" className={`theme-header-nav-items align-items-md-center d-flex px-md-3 ${isContactActive ? 'active' : ''}`}>Contact</Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}