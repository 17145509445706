export const NAME_REQUIRED = "Name is required.";
export const EMAIL_REQUIRED = "Email address is required.";
export const VALID_EMAIL = "Email must be a valid email.";
export const MINIMUM_15_CHAR = "Must be a minimum of 15 characters.";
export const MAXIMUM_30_CHAR = "Can not be a maximum than 30 characters.";
export const CHAR_MAX_300 = "Can exeed upto maximum 300 characters.";
export const SUBJECT_REQUIRED = "Subject is required.";
export const MESSAGE_REQUIRED = "Message is required.";
export const CAPTCH_INVALID = "Please Enter Captcha";
export const CONTACT_SEND_SUCCESS = "Query send Successfully";
export const MINIMUM_1_CHAR = "Must be a minimum of 1 characters.";
export const MAXIMUM_1000_CHAR = "Can not be a maximum than 1000 characters.";