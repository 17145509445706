import * as yup from "yup";
import {
    CHAR_MAX_300,
    EMAIL_REQUIRED,
    MAXIMUM_1000_CHAR,
    MAXIMUM_30_CHAR,
    MESSAGE_REQUIRED,
    MINIMUM_15_CHAR,
    MINIMUM_1_CHAR,
    NAME_REQUIRED,
    SUBJECT_REQUIRED,
    VALID_EMAIL
} from "./ValidationErrors";


/**
 * Contact Schema for Validation
 * @date 4/24/2023 - 5:47:03 PM
 *
 * @type {*}
 */

export const ContactSchema = yup.object().shape({
    name: yup.string().required(NAME_REQUIRED),
    email: yup.string().required(EMAIL_REQUIRED).email(VALID_EMAIL).matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,VALID_EMAIL),
    subject: yup.string().required(SUBJECT_REQUIRED).max(30, MAXIMUM_30_CHAR),
    message: yup.string().min(1, MINIMUM_1_CHAR).max(1000,MAXIMUM_1000_CHAR).test(
        'max-length',
        'Maximum character limit reached',
        (value) => !value || value.length <= 1000
      ).required(MESSAGE_REQUIRED)
});